






































import Downloader from "../../components/Others/Downloader.vue";
import BaseLink from "../../components/Base/BaseLink.vue";
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseWrapper from "../../components/Base/BaseWrapper.vue";
import { Vue, Component } from "vue-property-decorator";
import WebOthersNavigationBar from "@/components/Web/Others/NavigationBar.vue";

@Component({
    components: {
        WebOthersNavigationBar,
        BaseWrapper,
        BaseCard,
        BaseLink,
        Downloader,
    },
})
export default class WebGuides extends Vue {
    public guides: any[] = [
        // {
        //     text: "Admin",
        //     link: "guides/panduan_admin.pdf",
        // },
        {
            text: "Perusahaan",
            link: "guides/panduan_perusahaan.pdf",
        },
        {
            text: "Pengusul",
            link: "guides/panduan_pengusul.pdf",
        },
    ];
}
